import { FC, useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  DefaultUserValue,
  UserInterface,
  UserRoleInterface,
} from "../../../Interfaces/userManagement";
import { yupResolver } from "@hookform/resolvers/yup";
import { ModalDialog } from "../../../loadable";
import { MdCancel } from "react-icons/md";
import { BsSave } from "react-icons/bs";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import "./manageUserModal.scss";
import client, { handleError } from "../../../utils/axios";
import _ from "lodash";
import { UserRoleEnum } from "../../../enum/userRole";

interface ManageUserModalInterface {
  isShow: boolean;
  onClose: () => void;
  userRole: UserRoleInterface[];
  userData: UserInterface[];
  setUserData: (userData: UserInterface[]) => void;
  editUserData: UserInterface;
  setEditUserData: (editUserData: UserInterface) => void;
}

const ManageUserModal: FC<ManageUserModalInterface> = ({
  userRole,
  isShow,
  onClose,
  userData,
  setUserData,
  editUserData,
  setEditUserData,
}: ManageUserModalInterface) => {
  const { t } = useTranslation();
  const [userRoleState, setUserRoleState] = useState<UserRoleInterface[]>(userRole);
  const [selectedRole, setSelectedRole] = useState<UserRoleInterface>();
  const schema = yup.object().shape({
    cmuAccount: yup.string().required(t("CMU_ACCOUNT_REQUIRED")),
    name: yup.string().required(t("NAME_REQUIRED")),
    roleId: yup.number().required(),
  });

  useEffect(() => {
    if (editUserData.roleId !== UserRoleEnum.SUPER_ADMIN) {
      const filterUserRole = userRole.filter((e) => e.id !== UserRoleEnum.SUPER_ADMIN);
      setUserRoleState(filterUserRole);
    } else {
      setUserRoleState(userRole);
    }

    const findIndex = userRole.findIndex((user) => user.id === editUserData.roleId);

    if (!_.isNull(findIndex)) {
      setSelectedRole(userRole[findIndex]);
    } else {
      setSelectedRole(userRole[3]);
    }
  }, [userRole, editUserData, userData]);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<UserInterface>({
    resolver: yupResolver(schema),
    defaultValues: {
      roleId: 4,
    },
  });

  useEffect(() => {
    reset(editUserData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editUserData]);

  const handleCloseModal = () => {
    setEditUserData(DefaultUserValue);
    reset();
    onClose();
  };

  const onSubmit = async (data: UserInterface) => {
    const { cmuAccount, name, roleId } = data;
    const body = JSON.stringify({
      cmuAccount,
      name,
      roleId,
    });

    if (editUserData.id) {
      client
        .put(`/api/v1/users/${editUserData.id}`, body)
        .then((res) => {
          let userRows = userData.map((user) => {
            if (user.id === res.data.id) {
              return res.data;
            } else {
              return user;
            }
          });
          setUserData(userRows);
          handleCloseModal();
        })
        .catch((error) => {
          handleError(error, true);
        });
    } else {
      client
        .post(`/api/v1/users`, body)
        .then((res) => {
          let userRows = [...userData, res.data];
          setUserData(userRows);
          handleCloseModal();
        })
        .catch((error) => {
          handleError(error, true);
        });
    }
  };

  const onChangeRole = (role: SingleValue<UserRoleInterface>) => {
    if (role) {
      setSelectedRole({ id: role.id, name: role.name });
      setValue("roleId", role.id);
    }
  };

  const checkUserRole = (): boolean => {
    if (!_.isNull(editUserData.roleId) && editUserData.roleId === UserRoleEnum.SUPER_ADMIN) {
      return true;
    }

    return false;
  };

  return (
    <ModalDialog show={isShow} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>{editUserData.id > 0 ? t("EDIT_USER") : t("ADD_USER")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>{t("CMU_ACCOUNT")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("CMU_ACCOUNT")}
              {...register("cmuAccount", { required: true })}
              isInvalid={!!errors.cmuAccount}
              readOnly={editUserData.id > 0}
            />
            {errors.cmuAccount && (
              <Form.Control.Feedback type="invalid">
                {errors.cmuAccount.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("NAME_SURNAME")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("NAME_SURNAME")}
              {...register("name", { required: true })}
              isInvalid={!!errors.name}
            />
            {errors.name && (
              <Form.Control.Feedback type="invalid">{errors.name.message}</Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("USER_ROLE")}</Form.Label>
            <Select
              className={`custom-dropdown__container text-body3`}
              classNamePrefix="custom-dropdown"
              blurInputOnSelect={true}
              isSearchable={false}
              isDisabled={checkUserRole()}
              options={userRoleState}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id.toString()}
              onChange={(role) => onChangeRole(role)}
              value={selectedRole}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
            {errors.roleId && <span className="error-message">{t("ROLE_REQUIRED")}</span>}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={handleCloseModal}>
          <span className="me-1 mb-1">
            <MdCancel />
          </span>
          {t("CANCEL")}
        </Button>
        <Button variant="primary" type="submit" onClick={handleSubmit(onSubmit)}>
          <span className="me-2 mb-1">
            <BsSave />
          </span>
          {t("SAVE")}
        </Button>
      </Modal.Footer>
    </ModalDialog>
  );
};

export default ManageUserModal;
