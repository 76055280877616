import { Button, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./budgetReportButtonPanel.scss";
import Select, { SingleValue } from "react-select";
import { BsDownload } from "react-icons/bs";
import { FC, useCallback, useEffect, useState } from "react";
import { SelectItem } from "../../Interfaces/selectItem";
import _ from "lodash";

type BudgetReportButtonPanelProps = {
  allocateBudgetDropdown: SelectItem[];
  onChangeBudgetDropdown: (selectItem: SelectItem) => void;
  onClickDownloadFile: () => void;
};

const BudgetReportButtonPanel: FC<BudgetReportButtonPanelProps> = ({
  allocateBudgetDropdown,
  onChangeBudgetDropdown,
  onClickDownloadFile,
}) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<SelectItem>();

  const onChangeSelect = useCallback(
    (option: SingleValue<SelectItem>): void => {
      if (!_.isNull(option)) {
        setSelectedOption(option);
        onChangeBudgetDropdown(option);
      }
    },
    [setSelectedOption, onChangeBudgetDropdown]
  );

  useEffect(() => {
    setSelectedOption(allocateBudgetDropdown[0]);
  }, [allocateBudgetDropdown]);

  const renderDownloadButton = () => {
    return (
      <div className="budgetReportButtonPanel__download">
        <span className="me-2 mt-1">{t("DOWNLOAD_BUDGET_FILE")}</span>
        <Button variant="secondary" className="icon" onClick={onClickDownloadFile}>
          <BsDownload className="icon" />
        </Button>
      </div>
    );
  };

  return (
    <>
      <Container className="budgetReportButtonPanel">
        <div className="leftContainer">
          <Select
            className="budgetReportButtonPanel__select"
            blurInputOnSelect={true}
            isSearchable={false}
            options={allocateBudgetDropdown}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value.toString()}
            onChange={(option) => onChangeSelect(option)}
            value={selectedOption}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
        <div className="rightContainer">{renderDownloadButton()}</div>
      </Container>
      <div className="budgetReportButtonPanel__divider"></div>
    </>
  );
};

export default BudgetReportButtonPanel;
