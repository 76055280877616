import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import advanceFormat from "dayjs/plugin/buddhistEra";

import "./localization/config";
import Toast from "./components/toast";
import Sidebar, { SidebarProps } from "./components/sidebar/sidebar";
import Navbar from "./components/navbar/navbar";

import Routes from "./routes";

import "./scss/main.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { languagesEnum, languagesValueEnum } from "./enum/languages";
import { BudgetYearContext } from "./contexts/budgetYearContext";
import dayjs from "dayjs";
import { AuthProvider } from "./contexts/authContext";

dayjs.extend(advanceFormat);

function App() {
  const APP_VERSION = "1.0.2";
  const { i18n } = useTranslation();
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [language, setLanguage] = useState(
    localStorage.getItem("language") ?? languagesValueEnum.THAI
  );
  const [budgetYear, setBudgetYear] = useState<number>(0);

  const toggleSidebar = function (): void {
    setSidebarCollapsed(!isSidebarCollapsed);
  };

  const sidebarProps: SidebarProps = {
    appVersion: APP_VERSION,
    isSidebarCollapsed: isSidebarCollapsed,
  };

  useEffect(() => {
    const selectedBudgetYear = localStorage.getItem("budget-year");
    if (selectedBudgetYear) {
      setBudgetYear(Number(selectedBudgetYear));
    } else {
      //TODO: Need to define budget year
      setBudgetYear(Number(dayjs(new Date()).format("BBBB")));
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(language);
    localStorage.setItem("language", language);
  }, [language, i18n]);

  const onChangedLanguage = (lang: string) => {
    setLanguage(lang);
  };

  const getSelectedLanguage = () => {
    switch (language) {
      case languagesValueEnum.ENGLISH:
        return languagesEnum.ENGLISH;
      default:
        return languagesEnum.THAI;
    }
  };

  const onChangedBudgetYear = (year: number) => {
    localStorage.setItem("budget-year", year.toString());
    setBudgetYear(year);
  };
  
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"CMU Flex-Budget"}</title>
        <link rel="shortcut icon" href="/favicon.ico" />
      </Helmet>
      {/* We use BrowserRouter specifically because Router is on a higher-level and it will implicitly use one the implementations of Router by default anyway */}
      <BudgetYearContext.Provider value={budgetYear}>
      <AuthProvider>
        <BrowserRouter>   
          <Routes 
            sidebar={ <Sidebar {...sidebarProps} />} 
            navbar={  <Navbar
              toggleSidebar={toggleSidebar}
              onChangedLanguage={onChangedLanguage}
              getSelectedLanguage={getSelectedLanguage}
              budgetYear={budgetYear}
              onChangedBudgetYear={onChangedBudgetYear}
            /> }
          />
        </BrowserRouter>
        </AuthProvider>
        <Toast />
      </BudgetYearContext.Provider>
    </div>
  );
}

export default App;
