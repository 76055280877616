import Loadable from "react-loadable";
import Loading from "./components/loading";

const LoadableTestPlan = Loadable({
  loader: () => import("./pages/testPlan"),
  loading: Loading,
});
const LoadableHome = Loadable({
  loader: () => import("./pages/home"),
  loading: Loading,
});
const LoadableDashboard = Loadable({
  loader: () => import("./pages/home"),
  loading: Loading,
});
const LoadableModal = Loadable({
  loader: () => import("react-bootstrap/Modal"),
  loading: Loading,
});
const LoadableOrganizationChart = Loadable({
  loader: () => import("./pages/organizationChart"),
  loading: Loading,
});
const LoadableAuthLoginCallback = Loadable({
  loader: () => import("./pages/authLoginCallback"),
  loading: Loading,
});
const LoadableBackdoorAuth = Loadable({
  loader: () => import("./pages/backdoorAuth"),
  loading: Loading,
});
const LoadableAllocateBudget = Loadable({
  loader: () => import("./pages/allocateBudget"),
  loading: Loading,
});
const LoadableUserManagement = Loadable({
  loader: () => import("./pages/userManagement"),
  loading: Loading,
});
const LoadableBudgetReport = Loadable({
  loader: () => import("./pages/budgetReport"),
  loading: Loading,
});

export const TestPlan = LoadableTestPlan;
export const Home = LoadableHome;
export const Dashboard = LoadableDashboard;
export const ModalDialog = LoadableModal;
export const OrganizationChart = LoadableOrganizationChart;
export const AuthLoginCallback = LoadableAuthLoginCallback;
export const AllocateBudget = LoadableAllocateBudget;
export const UserManagement = LoadableUserManagement;
export const BudgetRepors = LoadableBudgetReport;
export const BackdoorAuth = LoadableBackdoorAuth;
