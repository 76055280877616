import { Col, Container, Stack } from "react-bootstrap";
import { CashCoin, CashStack, Wallet2 } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import BudgetCard from "../components/budgetCard/budgetCard";
import BudgetReportButtonPanel from "../components/budgetReportButtonPanel/budgetReportButtonPanel";
import Header from "../components/header/header";
import "../scss/pages/budgetReport.scss";
import Table from "../components/table/table";
import {
  ColumnDescription,
  ExpandHeaderColumnRenderer,
  ExpandColumnRendererProps,
} from "react-bootstrap-table-next";
import {
  BudgetDashboardInterface,
  BudgetListInterface,
  BudgetReportResponseInterface,
} from "../constants/budgetReport";
import { convertToCurrency } from "../utils/nubmerToString";
import { useContext, useEffect, useState } from "react";
import client, { handleError } from "../utils/axios";
import { BudgetYearContext } from "../contexts/budgetYearContext";
import { SelectItem } from "../Interfaces/selectItem";
import _ from "lodash";
import ArrowIcon from "../components/icon/arrowIcon";
import useBackDoor from "../hooks/useBackDoor";
import { handleExcelBlobAndDownload, newDateWithFormat } from "../utils/helper";
import { useOrganization } from "../hooks/useOrganization";

const BudgetReport = () => {
  const { t } = useTranslation();
  const { dateNow } = useBackDoor();
  const { loadingOrganization, organization } = useOrganization();
  const budgetYear = useContext(BudgetYearContext);
  const column: ColumnDescription[] = [
    {
      dataField: "nodeName",
      text: t("ORGANIZATION_NAME"),
      headerClasses: "text-center",
      align: "center",
      headerStyle: { cursor: "pointer" },
    },
    {
      dataField: "code",
      text: t("BUDGET_CODE"),
      align: "left",
    },
    {
      dataField: "description",
      text: t("BUDGET_DETAIL"),
      align: "left",
      headerStyle: () => {
        return { width: "500px" };
      },
    },
    {
      text: t("YEAR"),
      dataField: "",
      headerAlign: "right",
    },
    {
      dataField: "budget",
      text: t("BUDGET"),
      headerClasses: "d-flex justify-content-end",
      align: "right",
      formatter: (cell, row: BudgetListInterface) => {
        return convertToCurrency(row.budget);
      },
    },
  ];
  const [allocateBudgetDropdown, setAllocateBudgetDropdown] = useState<SelectItem[]>([]);
  const [budgetList, setBudgetList] = useState<BudgetListInterface[]>([]);
  const [allocateBudgetId, setAllocateBudgetId] = useState<number>();
  const [dashboardData, setDashboardData] = useState<BudgetDashboardInterface>({
    plannedBudget: 0,
    allocatedBudget: 0,
    remainBudget: 0,
  });

  const getAllocateBudgetDropdownData = () => {
    if (budgetYear > 0) {
      client
        .get(`api/v1/report/allocate-budgets`, {
          params: {
            organizationId: organization?.id ?? 0,
            budgetYear: budgetYear,
          },
        })
        .then((res) => {
          const dropdownData = res.data.map((e: number, index: number) => {
            return {
              label: `${t("TIME")} ${index + 1}`,
              value: e,
            };
          });

          setAllocateBudgetDropdown(dropdownData);
          getOrganizationInformation(dropdownData[0]);
        })
        .catch((error) => {
          handleError(error, true);
        });
    }
  }

  useEffect(() => {
    if (!loadingOrganization && organization) {
      getAllocateBudgetDropdownData();
    }
    // eslint-disable-next-line
  }, [budgetYear, loadingOrganization, organization]);

  const getOrganizationInformation = (selectItem: SelectItem) => {
    const allocateBudgetId = selectItem.value;
    setAllocateBudgetId(selectItem.value);

    client
      .get<BudgetReportResponseInterface>(`api/v1/report`, {
        params: {
          allocateBudgetId: allocateBudgetId,
        },
      })
      .then((res) => {
        setDashboardData({
          plannedBudget: res.data.plannedBudget,
          allocatedBudget: res.data.allocatedBudget,
          remainBudget: res.data.plannedBudget - res.data.allocatedBudget,
        });

        setBudgetList(res.data.budgetList.map(i => {return i}));
      })
      .catch((error) => {
        handleError(error, true);
      });
  };

  const onChangeBudgetDropdown = (selectItem: SelectItem): void => {
    getOrganizationInformation(selectItem);
  };

  const expandRow = {
    parentClassName: "budgetReport__expandParent",
    className: "budgetReport__expandChild",
    renderer: (row: BudgetListInterface) => {
      const { studentBudgets } = row;
      const result: JSX.Element[] = [];

      if (!_.isUndefined(studentBudgets)) {
        studentBudgets.forEach((budget) => {
          result.push(
            <Stack className="expandItem" direction="horizontal" gap={3}>
              <Col md={8}></Col>
              <Col className="expandItem__year">{budget.year}</Col>
              <Col className="expandItem__budget">{convertToCurrency(budget.budget)}</Col>
            </Stack>
          );
        });
      }

      return <>{result}</>;
    },
    expandHeaderColumnRenderer: ({ isAnyExpands }: ExpandHeaderColumnRenderer) => {
      if (isAnyExpands) {
        return <ArrowIcon direction="down" />;
      }
      return <ArrowIcon direction="right" />;
    },
    expandColumnRenderer: ({ expanded }: ExpandColumnRendererProps) => {
      if (expanded) {
        return <ArrowIcon direction="down" />;
      }
      return <ArrowIcon direction="right" />;
    },
    showExpandColumn: true,
  };

  const rowStyle = () => {
    return { cursor: "pointer" };
  };

  const onClickDownloadFile = () => {
    const now = newDateWithFormat(dateNow);

    client
      .get(`api/v1/organization-info/planning-budget/export`, {
        params: { allocateBudgetId: allocateBudgetId },
        responseType: "blob",
      })
      .then((res) => {
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats- officedocument.spreadsheetml.sheet",
        });
        handleExcelBlobAndDownload(blob, `${t("MENU_BUDGET_REPORT")} - ${now}.xlsx`);
      })
      .catch((error) => {
        handleError(error, true);
      });
  };

  return (
    <>
      <Header text={t("MENU_BUDGET_REPORT")} />
      <Container className="budgetReport">
        <BudgetReportButtonPanel
          allocateBudgetDropdown={allocateBudgetDropdown}
          onChangeBudgetDropdown={onChangeBudgetDropdown}
          onClickDownloadFile={onClickDownloadFile}
        />
        <div className="budgetReport__card-layout">
          <BudgetCard
            title={t("BUDGET_FROM_FACULTY")}
            icon={<Wallet2 />}
            variant="primary"
            background="total"
            budget={dashboardData.plannedBudget}
          />
          <BudgetCard
            title={t("BUDGET_PLANNED")}
            icon={<CashCoin />}
            variant="danger"
            background="allocated"
            budget={dashboardData.allocatedBudget}
          />
          <BudgetCard
            title={t("BUDGET_REMAINNING")}
            icon={<CashStack />}
            variant="success"
            background="remain"
            budget={dashboardData.remainBudget}
          />
        </div>

        <div className="budgetReport_table">
          <Table
            keyField="key"
            data={budgetList}
            columns={column}
            expandRow={expandRow}
            rowStyle={rowStyle}
            bordered={false}
          />
        </div>
      </Container>
    </>
  );
};

export default BudgetReport;
