import BootstrapTable, { BootstrapTableProps } from 'react-bootstrap-table-next';
import "./table.scss";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';



const Table: React.FC<BootstrapTableProps> = (props: BootstrapTableProps) => {
    const clone = {
        ...props,
        bordered: false,
    }
    return (
        <BootstrapTable
            {...clone}
        />
    );

};

export default Table;
