import { Container, Spinner } from 'react-bootstrap';

const Loading = () => {
  return (
    <Container className="text-center">
      <Spinner animation="grow" size="sm" variant="primary" className="me-2" />
      <Spinner animation="grow" size="sm" variant="primary" className="me-2" />
      <Spinner animation="grow" size="sm" variant="primary" className="me-2" />
    </Container>
  );
};

export default Loading;
