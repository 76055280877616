import { FC, ReactElement } from "react";
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardArrowUp,
} from "react-icons/md";

import "./arrowicon.scss";

interface ArrowProps {
  size?: number;
  direction: "up" | "left" | "right" | "down";
}

const ArrowIcon: FC<ArrowProps> = ({ size = 24, direction }): ReactElement => {
  const renderArrowIcon = () => {
    switch (direction) {
      case "up":
        return <MdKeyboardArrowUp size={size} />;
      case "down":
        return <MdKeyboardArrowDown size={size} />;
      case "left":
        return <MdKeyboardArrowLeft size={size} />;
      case "right":
        return <MdKeyboardArrowRight size={size} />;
      default:
        break;
    }
  };

  return <div className="arrowIcon">{renderArrowIcon()}</div>;
};

export default ArrowIcon;
