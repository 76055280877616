import axios from "axios";
import { toast } from "react-toastify";
import { logout } from "./cmuAuth";

const localStorageToken = localStorage.getItem("cmu-token");

const timeout = 10 * 60 * 1000;
const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: timeout,
  withCredentials: false,
  headers: {
    "Content-type": "application/json",
    Authorization: localStorageToken ? `Bearer ${localStorageToken}` : "",
  },
});

export const handleError = (error: any, showMessage: boolean) => {
  console.log(error);
  if (error?.response?.status === 401) {
    //force logout to re-login
    logout(() => {});
  }

  if (showMessage === true)
  {
    displayError(error);
  }
}

const displayError = (error: any) => {
  if (error.response) {
    // The client was given an error response (5xx, 4xx)
    toast.error(error.response.data.errors[0].message);
  } else if (error.request) {
    // The client never received a response, and the request was never left
    toast.error(error.request.responseText);
  } else {
    toast.error(error.message);
  }
}

export default client;

