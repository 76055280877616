import { FC, useState, MouseEvent, ReactElement, forwardRef } from "react";
import { Dropdown, DropdownButton, Image } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";
import { BoxArrowRight } from "react-bootstrap-icons";

import waveBackground from "../../assets/svg/nav/navbar-wave.svg";
import burgerMenu from "../../assets/svg/hamburger_menu.svg";
import appLogo from "../../assets/images/cmu13-logo.png";

import "./navbar.scss";
import { logout } from "../../utils/cmuAuth";
import { useUser } from "../../hooks/useUser";
import ConfirmModal from "../confirmModal/confirmModal";
import dayjs from "dayjs";
import { BUDGET_YEAR_RANGE, BUDGET_YEAR_START } from "../../constants/appConstant";


type CustomToggleProps = {
  children?: React.ReactNode;
  onClick?: (event: MouseEvent<HTMLAnchorElement, MouseEvent>) => {};
};

export interface NavbarProps {
  getSelectedLanguage: () => string;
  onChangedLanguage: (language: string) => void;
  toggleSidebar: () => void;
  budgetYear: number;
  onChangedBudgetYear: (year: number) => void;
}

const Navbar: FC<NavbarProps> = (props: NavbarProps) => {
  const { t } = useTranslation();
  const { user,logOut } = useUser();

  const [showChangeYearModal, setShowChangeYearModal] = useState<boolean>(false);
  const [selectedYear, setSelectedYear] = useState<number>(props.budgetYear);

  const handleChangeYear = (budgetyear: number) => {
    setShowChangeYearModal(true);
    setSelectedYear(budgetyear);
  };

  const handleCloseModal = () => {
    setShowChangeYearModal(false);
  };

  const handleConfirmModal = () => {
    setShowChangeYearModal(false);
    props.onChangedBudgetYear(selectedYear);
  };

  const CustomToggle = forwardRef((props: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => {
    return (
      <a
        className="user-dropdown"
        href="."
        ref={ref}
        onClick={(e: any) => {
          e.preventDefault();
          props.onClick && props.onClick(e);
        }}
      >
        {props.children}
        <>{user?.name}</>
      </a>
    );
  });

  const renderUserDropDown = (): ReactElement => {
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} />

        <Dropdown.Menu>
          <Dropdown.Item className="dropdown-logout">
            <button className="btn logout" onClick={() => logout(logOut)}>
              <BoxArrowRight className="item icon icon__user me-2 mt-1" /> {t("LOGOUT")}
            </button>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const getBudgetYear = () => {
    const thisYear = Number(dayjs(new Date()).format("BBBB"));
    let result = [];
    for (let i = BUDGET_YEAR_START; i < thisYear; i++)
    {
      result.push(i);
    }
    for (let i = thisYear; i < thisYear + BUDGET_YEAR_RANGE; i++)
    {
      result.push(i);
    }
    return result;
  }

  return (
    <>
      <div className="navbar">
        <SVG className="wave" src={waveBackground} />

        <div className="container">
          <div className="container__left">
            <button
              type="button"
              className="btn hamburger-menu"
              onClick={() => props.toggleSidebar()}
            >
              <SVG src={burgerMenu} />
            </button>
            <Image className="logo" src={appLogo} />
            <h3>Smart Budgeting</h3>
          </div>

          <div className="container__center"></div>

          <div className="container__right">
            <>
              <DropdownButton
                id="budget-year-ddl"
                variant="secondary"
                title={t("BUDGET_YEAR") + " " + props.budgetYear.toString()}
                onSelect={(e) => handleChangeYear(Number(e))}
              >
                {
                  getBudgetYear().map(i => { return (<Dropdown.Item eventKey={i}>{t("BUDGET_YEAR")} {i}</Dropdown.Item>) })
                }
              </DropdownButton>

              {renderUserDropDown()}
            </>
          </div>
        </div>
      </div>
      <ConfirmModal
        isShow={showChangeYearModal}
        onClose={handleCloseModal}
        onConfirm={handleConfirmModal}
        title={t("CHANGE_BUDGET_YEAR_MODAL_TITLE")}
        body={t("CHANGE_BUDGET_YEAR_MODAL_BODY")}
      />
    </>
  );
};

export default Navbar;
