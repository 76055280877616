import { useEffect, useState } from "react"
import { Organization } from "../Interfaces/organizationNode";
import client, { handleError } from "../utils/axios";

export const useOrganization = () => {
  const organizationCode = process.env.REACT_APP_ORGANIZATION_CODE;
  const [loadingOrganization, setLoadingOrganization] = useState<boolean>(false)
  const [organization, setOrganization] = useState<Organization>();

  useEffect(() => {
    setLoadingOrganization(true);
    getOrganization();
    // eslint-disable-next-line
  }, []);

  const getOrganization = () => {
    client
      .get<Organization>(`/api/v1/organization-info`, {
        params: {
          code: organizationCode,
        },
      })
      .then((response) => {
        setOrganization(response.data);
        setLoadingOrganization(false);
      })
      .catch((error) => {
        handleError(error, false);
        setLoadingOrganization(false);
      });
  };

  return { loadingOrganization, organization };
}