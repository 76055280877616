import { useEffect, useState } from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";
import {
  DefaultUserValue,
  UserInterface,
  UserRoleInterface,
} from "../../Interfaces/userManagement";
import SearchBox from "../searchBox/searchBox";
import { filter } from "lodash";
import { Button } from "react-bootstrap";
import { BsPlus } from "react-icons/bs";
import Table from "../table/table";
import MoreMenu from "../table/moreMenu/moreMenu";
import MoreMenuItem, {
  DeleteIcon,
  ReplyIcon,
  EditIcon,
  SuspendIcon,
  ActiveIcon,
} from "../table/moreMenu/moreItem";
import ConfirmModal from "../../components/confirmModal/confirmModal";
import _ from "lodash";
import ManageUserModal from "./form/manageUserModal";
import client, { handleError } from "../../utils/axios";
import "./manageUser.scss";
export interface UserMemberProps {
  userData: UserInterface[];
  userRole: UserRoleInterface[];
  setUserData: (userData: UserInterface[]) => void;
  deleteUser: (id: number) => void;
  getUserData: () => void;
}

const ManageUser: React.FC<UserMemberProps> = ({
  userData,
  userRole,
  setUserData,
  deleteUser,
  getUserData,
}) => {
  const { t } = useTranslation();
  const [userDataState, setUserDataState] = useState<UserInterface[]>(userData);
  const [editUserData, setEditUserData] = useState<UserInterface>(DefaultUserValue);
  const [activeStatusModalText, setActiveStatusModalText] = useState<string>("");
  const [userRoleState, setUserRoleState] = useState<UserRoleInterface[]>(userRole);
  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState<boolean>(false);
  const [showSuspendModal, setShowSuspendModal] = useState<boolean>(false);
  const [deleteUserId, setDeleteUserId] = useState<number | null>();
  const [suspendUserId, setSuspendUserId] = useState<number | null>();
  const columns: ColumnDescription[] = [
    {
      dataField: "",
      text: "ID",
      headerClasses: "text-center",
      align: "center",
      headerStyle: { cursor: "pointer", width: "6rem" },
      formatter: (cell: any, row: UserInterface, rowIndex: number) => {
        return <span>{rowIndex + 1}</span>;
      },
    },
    {
      dataField: "cmuAccount",
      text: t("CMU_ACCOUNT"),
      headerClasses: "table-header-column",
      classes: "table-column",
      align: "left",
    },
    {
      dataField: "name",
      text: t("NAME_SURNAME"),
      align: "left",
    },
    {
      dataField: "roleId",
      text: t("USER_ROLE"),
      headerStyle: { width: "12rem" },
      align: "left",
      formatter: (cell, row: UserInterface) => {
        const findRole = userRoleState.find((role) => role.id === row.roleId);

        if (findRole) {
          return findRole.name;
        }
      },
    },
    {
      dataField: "inactiveDate",
      text: t("ACTIVE_STATUS"),
      align: "center",
      headerStyle: { width: "7rem" },
      headerClasses: "text-center",
      formatter: (cell, row: UserInterface) => {
        return (
          <div className="status-button-wrapper mx-auto">
            <div className={`${row.inactiveDate ? "danger" : "success"}`}>
              {row.inactiveDate ? "ระงับการใช้งาน" : "ใช้งาน"}
            </div>
          </div>
        );
      },
    },
    {
      dataField: "actionMenu",
      text: "",
      isDummyField: true,
      align: "center",
      headerStyle: { cursor: "pointer", width: "10rem" },
      formatter: (cell, row: UserInterface) => {
        return (
          <MoreMenu>
            {!row && <MoreMenuItem text={t("ORGANIZATION_MEMBER_REPLY")} icon={ReplyIcon()} />}
            <MoreMenuItem text={t("EDIT")} icon={EditIcon()} onClick={() => onEditClick(row.id)} />
            <MoreMenuItem
              text={row.inactiveDate ? t("ACTIVE_USER") : t("SUSPEND_USER")}
              icon={row.inactiveDate ? ActiveIcon() : SuspendIcon()}
              onClick={() => onClickSuspend(row.id, row.inactiveDate ? "active" : "suspend")}
            />
            <MoreMenuItem
              text={t("DELETE")}
              icon={DeleteIcon()}
              onClick={() => onDeleteClick(row.id)}
            />
          </MoreMenu>
        );
      },
    },
  ];

  useEffect(() => {
    setUserDataState(userData);
    setUserRoleState(userRole);
  }, [userData, userRole]);

  const onAddClick = () => {
    setEditUserData(DefaultUserValue);
    setShowAddUserModal(true);
  };

  const onEditClick = (id: number) => {
    const findUser = userData.find((user) => user.id === id);

    if (findUser) {
      setEditUserData(findUser);
      setShowAddUserModal(true);
    }
  };

  const onDeleteClick = (id: number) => {
    setShowDeleteUserModal(true);
    setDeleteUserId(id);
  };

  const onConfirmDelete = () => {
    if (!_.isNull(deleteUserId) && !_.isUndefined(deleteUserId)) {
      deleteUser(deleteUserId);
    }

    setShowDeleteUserModal(false);
  };

  const onConfirmSuspend = () => {
    if (!_.isNull(suspendUserId) && !_.isUndefined(suspendUserId)) {
      suspendUser(suspendUserId);
    }
  };

  const onClickSuspend = (id: number, action: string) => {
    let modalText: string = "";
    action === "suspend"
      ? (modalText = t("SUSPEND_MODAL_TITLE"))
      : (modalText = t("ACTIVE_MODAL_TITLE"));

    setActiveStatusModalText(modalText);
    setShowSuspendModal(true);
    setSuspendUserId(id);
  };

  const suspendUser = (id: number) => {
    client
      .patch(`/api/v1/users/${id}/inactive`)
      .then((res) => {
        if (res.data) {
          setShowSuspendModal(false);
          getUserData();
        }
      })
      .catch((error) => {
        handleError(error, true);
      });
  };

  const searchData = (input: string) => {
    if (input) {
      let filteredData = filter(userData, function (o) {
        const inputLower = input.toLowerCase();
        return (
          o.name?.toLowerCase().includes(inputLower) ||
          o.cmuAccount?.toLocaleLowerCase().includes(inputLower)
        );
      });
      return filteredData;
    }

    return userData;
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="w-25">
          <SearchBox
            placeholder={t("SEARCH")}
            onChanged={(input: string) => setUserDataState(searchData(input))}
          ></SearchBox>
        </div>

        <Button variant="primary" onClick={onAddClick}>
          <BsPlus />
          <span className="ms-2">{t("ADD_USER")}</span>
        </Button>
      </div>

      <div className="mt-5">
        <Table keyField="id" data={userDataState} columns={columns} />
      </div>

      <ManageUserModal
        isShow={showAddUserModal}
        onClose={() => setShowAddUserModal(false)}
        userRole={userRole}
        userData={userData}
        setUserData={setUserData}
        editUserData={editUserData ?? DefaultUserValue}
        setEditUserData={setEditUserData}
      />

      <ConfirmModal
        isShow={showSuspendModal}
        onClose={() => setShowSuspendModal(false)}
        onConfirm={onConfirmSuspend}
        title={activeStatusModalText}
        body={activeStatusModalText}
        cancelText={t("CLOSE")}
        confirmText={t("OK")}
      />

      <ConfirmModal
        isShow={showDeleteUserModal}
        onClose={() => setShowDeleteUserModal(false)}
        onConfirm={onConfirmDelete}
        title={t("DELETE_MODAL_TITLE")}
        body={t("DELETE_MODAL_BODY")}
        cancelText={t("CLOSE")}
        confirmText={t("DELETE")}
      />
    </>
  );
};

export default ManageUser;
