import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(tz);

export const handleExcelBlobAndDownload = (blob: Blob, excelName: string): void => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", excelName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const containsOnlyNumbers = (str: string): boolean => {
  return /^\d+$/.test(str);
};

export const newDateWithFormat = (
  date: Date | string,
  format: string = "DD/MM/YYYY - HH:mm:ss"
): string => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return dayjs.utc(date).tz(timezone).local().format(format);
};
