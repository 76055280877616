import React from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import "../searchBox/searchBox.scss";
import { BsSearch } from "react-icons/bs";


export interface SearchBoxProps {
    placeholder?: string,
    onChanged: (inputString: string) => void,
    icon?: JSX.Element,
    showIcon?: Boolean
}

const SearchBox: React.FC<SearchBoxProps> = ({ placeholder, onChanged, icon, showIcon = true }) => {

    return (
        <InputGroup className="search-group">
            <Form.Control
                className="search-text-box"
                placeholder={placeholder}
                onChange={(event) => { onChanged(event.target.value) }}
            />
            {showIcon &&
                // <InputGroup.Text>
                //     {icon ? <>{icon}</> : <BsSearch className='' />}
                // </InputGroup.Text>
                <Button variant="primary" className='add-on'>
                    {icon ? <>{icon}</> : <BsSearch className='' />}
                </Button>
            }

        </InputGroup>
    );
};

export default SearchBox; 