import { FC, ReactNode } from "react";
import { Card } from "react-bootstrap";
import { Color } from "react-bootstrap/esm/types";
import "../budgetDashboard/budgetDashboard.scss";
import { convertToCurrency } from "../../utils/nubmerToString";

interface BudgetCardInterface {
  variant?: Color;
  icon: ReactNode;
  background: "total" | "allocated" | "remain";
  budget: number;
  title: string;
}

const BudgetCard: FC<BudgetCardInterface> = ({ variant, icon, background, budget, title }) => {
  return (
    <div className="budgetDashboard">
      <Card text={variant}>
        <Card.Body className="content">
          <div className={`icon-bg ${background}`}>{icon}</div>
          <div className="content__amount">{convertToCurrency(budget)}</div>
          <div>{title}</div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default BudgetCard;
