import { useContext, useMemo } from "react";
import { AuthContext } from "../contexts/authContext";
import { UserRoleEnum } from "../enum/userRole";
import { User } from "../Interfaces/apiToken";
import jwt_decode from "jwt-decode";

export const useUser = () => useContext(AuthContext);

export const useRole = () => {
  let user: User | undefined = undefined;
  const token = useMemo(() => localStorage.getItem("cmu-token"), []);

  const isApprover = (): boolean => {
    return user?.roleId === UserRoleEnum.APPROVER;
  };

  const isSuperAdmin = (): boolean => {
    return user?.roleId === UserRoleEnum.SUPER_ADMIN;
  };

  const isAdmin = (): boolean => {
    return user?.roleId === UserRoleEnum.ADMIN;
  };

  const isStaff = (): boolean => {
    return user?.roleId === UserRoleEnum.STAFF;
  };

  const checkPermission = (roleToCheck: UserRoleEnum): boolean => {
    return user?.roleId === roleToCheck;
  };

  if (token) {
    user = jwt_decode(token) as User;
    user.roleId = Number(user?.roleId);
  }

  return { user, isApprover, isSuperAdmin, isAdmin, isStaff, checkPermission };
};
