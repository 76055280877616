import { FC, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { Nav } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import { ISidebarItem } from "../../../constants/sidebarItem";

import upIcon from "../../../assets/svg/arrow_up.svg";
import downIcon from "../../../assets/svg/arrow_down.svg";

import SvgIcon, { SvgIconTypeMap } from "@mui/material/SvgIcon";
import { OverridableComponent } from "@mui/material/OverridableComponent";

const ItemLink: FC<ISidebarItem> = ({ path, text, group, icon }): JSX.Element => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const itemKey = path + text;

  const { t } = useTranslation();

  const isSelected = pathname === path; // TODO => Fix this

  const renderIcon = function (icon?: string | OverridableComponent<SvgIconTypeMap>): JSX.Element {
    if (!icon) {
      return <div />;
    } else if (typeof icon == "string") {
      return <SVG src={icon} className={"icon-custom-svg"} />;
    }

    return <SvgIcon className={"icon-mui-svg"} component={icon} />;
  };

  useEffect(() => {
    if (isSelected) {
      document.title = t(text);
      navigate(path as string);
    }
    // eslint-disable-next-line
  }, [isSelected, text, t]);

  const linkAction = (path: string | undefined) => {
    // TODO : check permission before push
    if (path) {
      localStorage.setItem("path", path);
      document.title = t(text);
      navigate(path as string);
    }
  };

  return (
    <>
      <Nav.Item
        key={itemKey}
        className={isSelected ? "selected" : ""}
        onClick={() => setShow(!show)}
      >
        <Nav.Link eventKey={itemKey} className={"sidebar-content"} onClick={() => linkAction(path)}>
          {renderIcon(icon)}
          <span>{t(text)}</span>
        </Nav.Link>
        {group?.length > 0 && (
          <SVG className="side__group_image" src={show ? upIcon : downIcon}></SVG>
        )}
      </Nav.Item>
      {show &&
        group?.length > 0 &&
        group.map((item) => {
          const selecedChild = item.path === pathname;
          return (
            <Nav.Item key={itemKey} className={selecedChild ? "selected" : ""}>
              <Nav.Link
                eventKey={itemKey}
                className={"side__group_item"}
                onClick={() => linkAction(item.path)}
              >
                {renderIcon(item.icon)}
                <span>{t(item.text)}</span>
              </Nav.Link>
            </Nav.Item>
          );
        })}
    </>
  );
};

export default ItemLink;
