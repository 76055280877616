import { FC } from "react";

import "./header.scss";

interface HeaderProps {
  text: string;
}

const Header: FC<HeaderProps> = (props: HeaderProps) => {
  return (
    <div className="header">
      <span>{props.text}</span>
    </div>
  );
}

export default Header;