import React from "react";
import { FC } from "react";
import { Dropdown } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import "./moreMenu.scss";

type CustomToggleProps = {
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {};
};

const CustomToggle = React.forwardRef(
  (props: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => {
    return (
      <a
        className="more-menu-a"
        href="."
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          props.onClick && props.onClick(e);
        }}
      >
        {props.children}
        <BsThreeDots />
      </a>
    );
  }
);

const MoreMenu: FC<{
  /// User MoreMenuItem
  children?: React.ReactNode[];
}> = ({ children }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} />
      <Dropdown.Menu>{children}</Dropdown.Menu>
    </Dropdown>
  );
};

export default MoreMenu;
