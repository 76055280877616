import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Header from "../components/header/header";
import ManageUser from "../components/manageUser/manageUser";
import { UserRoleEnum } from "../enum/userRole";
import { useRole } from "../hooks/useUser";
import { UserInterface, UserRoleInterface } from "../Interfaces/userManagement";
import client, { handleError } from "../utils/axios";
import axios from "axios";

const UserManagement = () => {
  const { t } = useTranslation();
  const [userData, setUserData] = useState<UserInterface[]>([]);
  const [userRole, setUserRole] = useState<UserRoleInterface[]>([]);
  const navigate = useNavigate();
  const { checkPermission } = useRole();

  useEffect(() => {
    if (!checkPermission(UserRoleEnum.SUPER_ADMIN)) {
      navigate("/");
    }

    getUserData();
    //eslint-disable-next-line
  }, []);

  const getUserData = () => {
    Promise.all([client.get(`/api/v1/users`), client.get(`/api/v1/users/roles`)])
      .then(
        axios.spread((userData, userRoleData) => {
          setUserData(userData.data);
          setUserRole(userRoleData.data);
        })
      )
      .catch((error) => {
        handleError(error, true);
      });
  };

  const deleteUser = (id: number) => {
    client
      .delete(`/api/v1/users/${id}`)
      .then((res) => {
        if (res.data.isSuccess) {
          getUserData();
        }
      })
      .catch((error) => {
        handleError(error, true);
      });
  };

  return (
    <>
      <Header text={t("MENU_USER_MANAGEMENT")} />
      <Container>
        <ManageUser
          userData={userData}
          userRole={userRole}
          setUserData={setUserData}
          deleteUser={deleteUser}
          getUserData={getUserData}
        />
      </Container>
    </>
  );
};

export default UserManagement;
