import { FC, ReactElement, useEffect } from "react";
import { Routes as Switch, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "./constants/path";
import { Home, TestPlan, OrganizationChart, AuthLoginCallback, AllocateBudget, BackdoorAuth } from "./loadable";
import BudgetReport from "./pages/budgetReport";
import UserManagement from "./pages/userManagement";
import { getBypassToken, getUserAuthorizationRequest } from "./utils/cmuAuth";
import { useUser } from "./hooks/useUser";

interface RoutesProps {
  sidebar: ReactElement;
  navbar:ReactElement;
}

// Assign "Pages" to a "/route".
const Routes:FC<RoutesProps> = ({sidebar,navbar}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setAuthValue,isAuthenticated } = useUser();

  useEffect(() => {
    if (location.pathname !== "/auth-call-back" && !isAuthenticated()) {
      triggerImmediateLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const triggerImmediateLogin = () => {
    const devRole = process.env.REACT_APP_DEV_ROLE;

    if (devRole) {
      //dev mode
      getBypassToken(devRole,undefined,setAuthValue)
        .then((data) => {
          navigate("/");
        })
        .catch((error) => {
          toast.error(error);
        });
    } else {
      window.location.href = getUserAuthorizationRequest();
    }
  };
  return (
    <>
      {isAuthenticated()? <>{navbar}</>:<></>}
      <div className="app">
        {isAuthenticated()? <>{sidebar}</>:<></>}
        <div className="app-container">
          <Switch>
            <Route path="/" element={<Navigate to={PATH.ORGANIZATION_CHART} />} />
            <Route key={PATH.HOME} path={PATH.HOME} element={<Home />} />
            <Route key={PATH.TEST_PLAN} path={PATH.TEST_PLAN} element={<TestPlan />} />
            <Route
              key={PATH.ORGANIZATION_CHART}
              path={PATH.ORGANIZATION_CHART}
              element={<OrganizationChart />}
            />

            {/* TODO: Uncomment this when you want to test each role job */}
            <Route key={PATH.TEST_LOGIN} path={PATH.TEST_LOGIN} element={<BackdoorAuth />} />

            <Route key={PATH.AUTH_CALLBACK} path={PATH.AUTH_CALLBACK} element={<AuthLoginCallback />} />
            <Route key={PATH.ALLOCATE_BUDGET} path={PATH.ALLOCATE_BUDGET} element={<AllocateBudget />} />
            <Route key={PATH.USER_MANAGEMENT} path={PATH.USER_MANAGEMENT} element={<UserManagement />} />
            <Route key={PATH.BUDGET_REPORT} path={PATH.BUDGET_REPORT} element={<BudgetReport />} />
          </Switch>
        </div>
      </div>
    </>
  );
};

export default Routes;
