import { FC, Key } from "react";
import { Dropdown } from "react-bootstrap";
import { BsFillTrashFill, BsReply } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";
import { FaUserTimes, FaUserCheck } from "react-icons/fa";
export interface MoreMenuItemProps {
  key?: Key | null | undefined;
  text?: string;
  icon?: JSX.Element;
  onClick?: () => void;
}

const MoreMenuItem: FC<MoreMenuItemProps> = ({ key, text, icon, onClick }) => {
  return (
    <Dropdown.Item className="more-menu-item" onClick={onClick} key={key}>
      {icon && icon}
      {text && text}
    </Dropdown.Item>
  );
};

export const DeleteIcon = () => {
  return <BsFillTrashFill />;
};

export const SuspendIcon = () => {
  return <FaUserTimes />;
};

export const ReplyIcon = () => {
  return <BsReply />;
};

export const EditIcon = () => {
  return <MdModeEdit />;
};

export const ActiveIcon = () => {
  return <FaUserCheck />;
};

export default MoreMenuItem;
