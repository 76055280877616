export enum PATH {
  HOME = "/home",
  DASHBOARD = "/dashboard",
  BUDGET_REPORT = "/budget-report",
  ORGANIZATION_CHART = "/organization-chart",
  USER_MANAGEMENT = "/user-management",
  TEST_PLAN = "/testPlan",
  TEST_GROUP = "/testGroup",
  ITEM_1 = "/1",
  ITEM_2 = "/2",
  AUTH_CALLBACK = "/auth-call-back",
  ALLOCATE_BUDGET = "/allocate-budget",
  ALLOCATE_NODE_MANAGEMENT = "/allocate-budget/:nodeId",
  ALLOCATE_BUDGET_MANAGEMENT_ADD = "/allocate-budget/:nodeId/budget/add",
  ALLOCATE_BUDGET_MANAGEMENT_EDIT = "/allocate-budget/:nodeId/budget/:budgetId",
  TEST_LOGIN = "/test-login/:roleId",
}
