import { SvgIconTypeMap } from "@mui/material/SvgIcon";
import { PATH } from "./path";

// **** Just copy import from this repository in the link below. ****
// https://mui.com/material-ui/material-icons/
// ******************************************************************
import { OverridableComponent } from "@mui/material/OverridableComponent";
import SchemaOutlinedIcon from "@mui/icons-material/SchemaOutlined";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
export interface ISidebarItem {
  path?: string;
  text: string;
  icon?: string | OverridableComponent<SvgIconTypeMap>;
  isgroup: boolean;
  isShow: boolean;
  group: ISidebarItem[];
}

export const SIDEBAR_ITEMLIST: ISidebarItem[] = [
  {
    path: PATH.ORGANIZATION_CHART,
    text: "MENU_ORGANIZATION_MANAGEMENT",
    icon: SchemaOutlinedIcon,
    isgroup: false,
    isShow: true,
    group: [],
  },
  {
    path: PATH.ALLOCATE_BUDGET,
    text: "MENU_BUDGET_MANAGEMENT",
    icon: CurrencyExchangeOutlinedIcon,
    isgroup: false,
    isShow: true,
    group: [],
  },
  {
    path: PATH.BUDGET_REPORT,
    text: "MENU_BUDGET_REPORT",
    icon: AssessmentOutlinedIcon,
    isgroup: false,
    isShow: true,
    group: [],
  },
  {
    path: PATH.USER_MANAGEMENT,
    text: "MENU_USER_MANAGEMENT",
    icon: PeopleOutlinedIcon,
    isgroup: false,
    isShow: false,
    group: [],
  },
];
