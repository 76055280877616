export interface UserInterface {
  cmuAccount: string;
  id: number;
  inactiveDate: Date | string | null;
  name: string;
  roleId: number;
}

export interface UserRoleInterface {
  id: number;
  name: string;
}

export const DefaultUserValue = {
  cmuAccount: "",
  id: 0,
  inactiveDate: null,
  name: "",
  roleId: 4,
};
