const useBackDoor = () => {
  const storageItem = localStorage.getItem("appDateTime");

  // EXAMPLE 2565/11/30
  let dateNow = storageItem ? new Date(storageItem) : new Date();

  return { dateNow };
};

export default useBackDoor;
